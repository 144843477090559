import axios from '@axios'

export default {
  namespaced: true,
  state: {
    // Важность
    calendarOptions: [
      {
        color: 'primary',
        label: 'Бокс 4',
      },
      {
        color: 'success',
        label: 'Бокс 5',
      },
    ],
    selectedCalendars: ['Бокс 4', 'Бокс 5'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/calendar', {
            params: {
              filter: calendars.join(','),
            },
          })
          .then(response => {
            // console.log(response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: '/calendar',
          data: event,
        })
          .then(response => {
            // console.log(response.data.data)
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: `/calendar/update/${event.id}`,
          data: event,
        })
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/calendar/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
